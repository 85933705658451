import React from 'react'
import {Canvas} from '@react-three/fiber'
import SceneHandler from '../Scene/SceneHandler'
import Header, { HeaderNoGraphics, HomeHeader } from '../../HeaderFooter/Header'


export default function InterfacePage(props)
{
    return(
        <>
            
            <SceneHandler />
        </>
    )
}