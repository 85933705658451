import './watermark.css'
import '../../css/App.css'

export default function Watermark(props)
{
    return(
        <div className = "WatermarkContainer">
            <h2 className='WMH2 DisplayFont'>Developer: &nbsp;
            
            <a 
            style = {{color: "white"}}
            href = "https://www.linkedin.com/in/cameron-warnick-64a25222a/" target = "_blank" referrer = "noreferrer">
            Cameron Warnick
            </a>
            
            </h2>
            <h2 className='WMH2 DisplayFont'>Graphic Designer: &nbsp;
            
            <a 
            style = {{color: "white"}}
            href = "https://www.linkedin.com/in/kim-hoang-466134172/" target = "_blank" referrer = "noreferrer">
            Kim Hoang
            </a>

            </h2>
        </div>
    )

}